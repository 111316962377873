import { NextApiRequestCookies } from 'next/dist/server/api-utils';

import Cookie, { CookieAttributes } from 'js-cookie';

import {
  CMS_ACCESS_TOKEN,
  PROFILE_ACCESS_TOKEN_KEY,
  USER_ACCESS_TOKEN_KEY,
} from 'api/utils';

const getDomain = (domain?: string): string | undefined => {
  const isPreview =
    document?.location?.hostname?.endsWith('.vercel.app') &&
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview';
  if (isPreview) {
    return undefined;
  }

  return domain ?? process.env.NEXT_PUBLIC_DOMAIN;
};

const setCookie = (key: string, value: string, options?: CookieAttributes) => {
  const domain = getDomain(options?.domain);

  Cookie.set(key, value, {
    sameSite: 'lax',
    secure: !(domain === 'localhost'), // Safari does not save secure cookies on localhost
    expires: 30,
    domain,
    path: '/',
    ...options,
  });
};

export const removeCmsAuthCookie = () => {
  Cookie.remove(CMS_ACCESS_TOKEN);
};

export const getCmsAuthCookie = () => {
  return Cookie.get(CMS_ACCESS_TOKEN);
};

export const getCmsAuthCookieFromNest = (cookies?: NextApiRequestCookies) => {
  return cookies ? cookies[CMS_ACCESS_TOKEN] : undefined;
};

export const setCmsAuthCookie = (accessToken: string) => {
  setCookie(CMS_ACCESS_TOKEN, accessToken, {
    sameSite: 'strict',
    expires: 10,
  });
};

export const setAccessCookies = (
  accessToken: string,
  options?: CookieAttributes
) => {
  setCookie(USER_ACCESS_TOKEN_KEY, accessToken, options);
  setCookie(PROFILE_ACCESS_TOKEN_KEY, accessToken, options);
};
